@media (min-width: 0px) and (max-width: 425px){
  .contactarea .section-intro {height: auto;}
    .single-room .section-intro {
        height: auto;
      }
  .double-room .section-intro{
    height: auto;
  }
  #naturo-image{
    height: auto;
  }
  .naturopura .section-intro {
    height: auto;
  }
                            .sap .section-intro {
                                height: auto;
                              }
  .galleryarea {
   height: auto;
}
             .team .section-intro {
               height: auto;
             }
                .service .section-intro {
                  height: auto;
                }
    .section-intro-title {
      text-align: center;
      color: #fff;
      font-size: 35px;
      position: relative;
      padding: 30px;
    }
.about_img img {
    width: 100%;
    transition: none;
}
#pool{
  flex-direction: column-reverse;
}
.banner1 .section-intro {height: auto;}
  .why-us {font-size: 28px;}
  ul.bronzee li {
    width: 38%;
  }
  .navbar .container {
    padding-left: 18px;
    /* border-radius: 20px;
    background: #fff; */
  }
    .boxcard{
      height: auto;
    }
  .all-tech_box {
    flex: 2 0 38%;
  }
  .left-column{
    height: auto;
  }
  .banner1 .section-intro p {
    padding-left: 20px;
  }
  .keyfeature-card {
    padding: 10px;
    margin-top: 20px;
    width: 40%;
  }
  .tech-desc{
    padding: 0px 20px;
  }
  .box-transparent img{
    height: 50px;
  }
  .contact-text .decor-left{
    width:65px;
  }
  .img-fluid1 {
    width: 100%;
    height: auto;
  }
  .flutter-healthcare {
    height: auto;
  }
  .contact-text .decor-right{
    width:65px;
  }
  .trustedcmpny {
    height: auto;
  }
  .featurebox {
    height: auto;
  }
  .h-trustedcmpny.trustedcmpny { 
  height: auto;
  }
  .sec-title-style1 .bottom-text p {font-size: 13px;}
  .adrs{font-size: 13px;}
  .grid {
    width: 100%;
    height: 980px;
  }
.item10{
  display: none;
}
.grid .gp{
  display: none;
}
.client-area h2 {
  font-size: 32px;
  line-height: 40px;
  margin: 30px;
  padding: 0px 20px;
}
.client-area-country img {
  transition-duration: 4s;
  width: 100%;
}
.grid h2 {
  font-size: 16px;
  margin: 0px;
  color: #fff;
}
.cta-content h2 {
  font-size: 26px;}
.item11{
  display: none;
  }
.tool-text {
  padding: 10px 0px;
}
.team-sap {
  padding: 0px 20px;
}
#footer .footer-top .footer-info {
  padding: 0px 20px;
}
#footer .footer-top .footer-links {
  padding: 0px 20px;
}
.inner-service-heading{
  padding-top: 10px;
  font-size: 28px;
  }
.languages h2 {
  font-size: 28px;
}
#services{
  padding: 40px 20px 40px 20px;
}
.client-area{
  padding: 40px 20px 40px 20px;
}
.item4 {
  pointer-events: visible;}
.item3{
    pointer-events: visible;
  }
.logotop {
  height: 50px;
  /* margin-top: 5px; */
  margin-left: 0px;
}
.banner-text-1 {
  font-size: 32px;
  padding: 40px 20px 40px 20px;
  margin: 0;
  width: 100%;
  line-height: 40px
}
.second-banner{
  padding: 30px 0px;
}

.social-links img{
  display: inline;
}
#tabs .main-rounded-button span {font-size: 14px;}
.trustedcmpny h2 {font-size: 29px;}
.content h1 {
  font-size: 29px;
}
.section-heads h3 {font-size: 29px;}
.trustedcmpny ul {
  display: inline-grid;
  text-align: center;
}
.trustedcmpny ul li p {
  font-size: 15px;
  margin-top: 0px;
}
.section-header h3 {
  font-size: 32px;
}
#clients {
  padding: 40px 20px;
}
.uuu {
  margin-top: 0px;
  font-size: 32px;
  line-height: 56px;
}
.trustedcmpny ul li {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.grid p {
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  font-family: "Poppins", Sans-serif;
}
#about .right-text p {
  margin-bottom: 40px;
  font-size: 13px;
}
#our-classes p {
  font-size: 14px;
  padding: 0px 20px;
  padding-bottom: 30px
}
#our-classes {margin-bottom: 30px;}
#tabs ul li a {
  font-size: 13px;
}
.hexagon {
  width: 33% !important;
  height: auto !important;
  border: 1px solid #e5e5e5 !important;
  padding: 9px !important;
  margin: 0 !important;
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.client_logo-block .our_client img {
  width: auto !important;
}
.client_logo_block_home h3:before {
  top: 50px !important;
}
.client_logo-block ul li {
  text-align: left;
}
.hexagon::before{
  transform: none;
}
.hexagon::after {
  transform: none;
}
.client_logo-block {
  padding: 30px 0 20px 0 !important;
  text-align: center;
}
.client_logo-block .our_client img {
  width: auto !important;
}
.secndrow {
  margin-left: 0px; 
  margin-top: 0px; 
  float: none; 
  margin-bottom: 27px;
  }
    .client_logo-block .our_client span {
      color: #000;
      white-space: no-wrap;
      font-size: 11px;
      font-weight: 600;
      display: block;
      line-height: 4px;
      padding: 6px;
    }
}
@media (min-width: 426px) and (max-width: 767px){
 
  .client_logo-block ul li {
    text-align: left;
  }
.hexagon::before {
  transform: none;
}

.hexagon::after {
  transform: none;
}

.client_logo-block {
  padding: 30px 0 20px 0 !important;
  text-align: center;
}

.client_logo-block .our_client img {
  width: auto !important;
}

.secndrow {
  margin-left: 0px;
  margin-top: 0px;
  float: none;
  margin-bottom: 27px;
}
  ul.bronzee li {
    width: 70%;
  }
  .banner-text-1{
    font-size: 35px;
    text-align: left;
    padding: 45px 40px;
    margin-bottom: 0;
  }
  .img-fluid {
    width: 100%;
    height: auto;
  }
  .logotop {
    width: 135px;
    height: 40px;
    margin-left: 0px;
  }
  .about_img{
    display: none;
  }
  .content h1 {
    font-size: 35px;
}
.grid p{
  font-size: 15px;
}
.grid{
  grid-template-rows: none !important;
  padding: 40px;
  display: block;
}
.card{
  height: 200px;
  display: none;
}
.trustedcmpny{
  height: auto;
}
.trustedcmpny h2 {
  font-size: 28px;
}
#million {font-size: 14px;}
.uuu{
  margin: 0;
  font-size: 30px;
  line-height: 50px;
}
#services {
  padding: 40px 20px 40px 20px;}
.rrr {
  padding: 20px 20px;
}
.rrr-y {
  padding: 20px 0px;
}
.trustedcmpny ul li p {
  font-size: 13px;
  padding: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
} 
.wrapper{
  padding: 0;
}
.client-area h2 {font-size: 30px;
  padding: 0px 30px;
  margin-top: 0;
  line-height: 50px;
}
.client-area-country img {
  width: 100%;}
  .section-header h3 {
    font-size: 32px;
  padding: 0;}
.tabs-content img{
  display: none;
}
}

.requirement_para {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #242424;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}


@media (min-width: 768px) and (max-width: 1023px){
  .hexagon{
      width: 90px !important;
        height: 82px !important;
        border: 1px solid #e5e5e5 !important;
        padding: 9px !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
}
.client_logo-block .our_client span {
  line-height: 16px;
}
  .hexagon:before {
      transform: none;
    }
  
    .hexagon:after {
      transform: none;
    }
  
    .client_logo-block {
      padding: 30px 0 20px 0 !important;
      text-align: center;
    }
  
    .client_logo-block .our_client img {
      width: auto !important;
    }
  
    .secndrow {
      margin-left: 0px;
      margin-top: 0px;
      float: none;
      margin-bottom: 27px;
    }
  .flutter-healthcare {
    height: auto;
  }
    .icon-wrapper {
      padding: 10px;
      align-self: end;
    }
    .project-name p {
      font-size: 18px;
    }
  .navbar .container {
      padding-left: 18px;
      border-radius: 20px;
      background: #fff;
    }
  #nav {
    color: #000;
  }
  .dropdown-toggle{
    background-color: transparent;
    color: #000;
  }
.about_img img {
  width: 100%;
  display: none;
  transition: none;
}
.client-area h2 {
  font-size: 40px;
  padding: 40px 50px;
}
.col-md-6 {
  flex: 1;
  width: 100%;
}
.boxcard .gp{
  overflow: hidden;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.keyfeature-card {
  width: 47%;
  padding: 10px;
  margin: 10px 4px;
}
  ul.bronzee li {
    width: 15%;
  }
  .grid p{
    width:350px;
  }
  .grid .gp{
    display: none;
  }
  .img-fluid1 {
  width: 100%;
  height: auto;
  }
.banner-text-1 {
  font-size: 52px;
  padding: 60px 0px;
  margin: 0;
  }
.about_img{
  margin: 0;
}
.grid {
  height: 500px;
  /* grid-
  template-columns: repeat(2, 1fr); */
  grid-template-rows: repeat(6, minmax(auto, 100px));
  /* overflow-y: scroll; */
}
.item7 {
  grid-area: 4/4/5/5;
}
.item2 {
  grid-area: 3/4/3/4;
}
.item10{
  grid-template-columns: 5/5/3/6;
}
.grid h2 {
  font-size: 15px;
  margin: 0;
  text-align: center;
}
#million {
  font-size: 14px;
}
.requirement_para {
  display: block;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #242424;
}
.content h1 {
  font-size: 32px;
  text-align: left;
  padding: 0px 20px;
}
.trustedcmpny {
 height: auto;
}
.trustedcmpny h2 {
font-size: 35px;

}
.trustedcmpny ul li p {
  color: #000 !important;
  font-size: 18px;
  margin-top: 15px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
}
@media (max-width: 400px) {
  h1 {
      font-size: 28px;
  }
}

@media (max-width: 1401px) {
  /* .navbar .container {
      padding-left: 35px;
      border-radius: 30px;
      background: #fff;
    } */

      
    #about-main .jumbotron {
      height: 595px !important;
    }
    #about-main .jumbotron .jumbotron-inner {
      max-width: 900px;
    }
    #about-main .jumbotron .jumbotron-inner .top-box {
      max-width: 900px;
    }
    #about-main .jumbotron .jumbotron-inner .top-box .content-box {
      margin-top: 30px;
      margin-bottom: 50px;
    }
    #about-main .jumbotron .jumbotron-inner .top-box .content-box h3 {
      font-size: 35px;
      margin: 5px 0px 20px 0px;
      color: #6f7782;
      font-weight: 300;
    }
  }
  @media (max-width: 1000px) {
    #about-main .jumbotron .img-layer-container {
      width: 100%;
    }
  }
  @media (max-width: 815px) {
    #about-main .jumbotron .jumbotron-inner {
      max-width: 600px;
    }
  }
  @media (max-width: 640px) {
    #about-main .jumbotron {
      height: 400px !important;
    }
    #about-main .jumbotron .jumbotron-inner {
      max-width: 550px;
      padding: 0 20px;
    }
    #about-main .jumbotron .img-1, #about-main .jumbotron .img-2, #about-main .jumbotron .team-image {
      display: none;
    }
  }
  @media (max-width: 420px) {
    #about-main .jumbotron .jumbotron-inner {
      max-width: 600px;
      padding: 0 20px;
    }
    /* .about_img {
      display: none;} */
  }
  @media (max-width: 400px) {
    #about-main .jumbotron .jumbotron-inner .top-box .content-box {
      margin: 30px 0;
    }
    #about-main .jumbotron .jumbotron-inner .top-box .content-box h1 {
      font-size: 35px;
    }
    #about-main .jumbotron .jumbotron-inner .top-box .content-box p {
      font-size: 16px;
    }
  }
  #about-main .story-container {
    height: 100%;
    width: 100%;
    margin-top: 150px;
  }
  @media (max-width: 640px) {
    #about-main .story-container {
      margin-top: 80px;
    }
  }
  #about-main .story-container .container-divider {
    margin-top: 100px;
  }
  @media (max-width: 640px) {
    #about-main .story-container .container-divider {
      margin-top: 50px;
    }
  }
  @media (max-width: 815px) {
    #about-main .story-container .need-for-dx-container, #about-main .story-container .our-tech-container, #about-main .story-container .origin-story-container, #about-main .story-container .today-container {
      width: 100%;
    }
  }
  @media (max-width: 640px) {
    #about-main .story-container .need-for-dx-container p, #about-main .story-container .our-tech-container p, #about-main .story-container .origin-story-container p, #about-main .story-container .today-container p {
      font-size: 13px;
    }
  }
  @media(max-width:1400px) {
    .fashion_industry_innerbox {
      min-height: 260px;
    }
    .development_process_innerboxs ul li {
      height: 214px;
    }
    span.requirement_para {
      font-size: 12px;
    }
  }
@media(max-width:767px) { 
  .client_logo-block {
    padding: 70px 0 100px 0 !important;
  }
  .requirement_para {
    font-size: 12px;
  }
  .development_process_innerboxs ul li:nth-child(1) {
    position: static;
  }
  .development_process_innerboxs ul li {
    height: 252px;
    width: 100%;
  }
  .development_process_innerboxs ul li:nth-child(2) {
    position: static;
  }
  .development_process_innerboxs ul li:nth-child(3) {
    position: static;
  }
  .development_process_innerboxs ul li:nth-child(4) {
    position: static;
  }
  .development_process_innerboxs ul li:nth-child(5) {
    position: static;
  }
}
@media (min-width: 768px) and (max-width: 1023px) { 
.left-column p{
  overflow: hidden;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.development_process_innerboxs ul li {
  padding: 35px 50px;
  width: 25%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
}       
@media (min-width: 1024px) and (max-width: 1440px){
  .client_logo-block .our_client span {
    line-height: 13px;
  }
  .banner1 .section-intro {
    height: auto;
    padding: 100px 0px 0px 0px;
  }
    .about_img {margin: 0;
    padding: 0;}
  .banner-text-1 {
    font-size: 50px;
  }
.keyfeature-card{
  height: auto;  
}
.navbar .container {
  padding-left: 35px;
  border-radius: 30px;
  background: #fff;
}
.left-column p{
  font-size: 12px;
}
.boxcard {
  height: auto;
}
.left-column{
  font-size: 12px;
}
.gp{
    font-size: 14px;
  }
}
.navbar-collapse {
  padding-right: 35px;
}
@media (max-width: 1441px) and (min-width: 2571px) {
  .main-timeline5 .timeline {
      margin-top: -93px
    }
         .main-timeline5 .timeline-content:before {
           left: -2%
         }
    
         .main-timeline5 .timeline:nth-child(2n) .timeline-content:before {
           right: -2%
         }
.navbar .container {
  padding-left: 35px;
  border-radius: 20px;
  background: #fff;
}
.banner-text-1 {
  padding: 80px 0px 0px 0px;
  }
}
@media screen and (min-width: 2560px) {
.navbar .container {
  padding-left: 35px;
  border-radius: 20px;
  background: #fff;
}
.banner-text-1 {
  padding: 80px 0px 0px 0px;
  }
}
@media(min-width: 992px) and (max-width: 1199px) {
  .hexagon:before{
    transform: none;
  }
    .hexagon:after {
      transform: none;
    }
 .hexagon {
   width: 82px !important;
   height: 82px !important;
   border: 1px solid #e5e5e5 !important;
   padding: 9px !important;
   margin: 0 !important;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
 }
 .secndrow {
   margin-left: 0px;
   margin-top: 0px;
   float: none;
   margin-bottom: 27px;
 }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .client_logo-block .our_client {
      float: left;
      text-align: center;
    }
  .client_logo-block .our_client span {
      line-height: 15px;
      padding: 3px;
    }
.hexagon {
  width: 82px !important;
  height: 82px !important;
  border: 1px solid #e5e5e5 !important;
  padding: 9px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
   }
        .hexagon:before {
          transform: none;
        }
    
        .hexagon:after {
          transform: none;
        }
}